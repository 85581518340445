import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { qmFetchMatchedTrends, QMMatchedTrendsInput } from '@berkindale/berkindale-provider-quotemedia-javascript-api';
import { RootState } from "../../app/store";

interface FetchMatchedTrendsParams extends QMMatchedTrendsInput {}

interface MatchedTrendsState {
    trends: any[];
    loading: boolean;
    error: string | null;
}

export const fetchMatchedTrends = createAsyncThunk(
    'matchedTrends/fetchMatchedTrends',
    async (params: FetchMatchedTrendsParams, thunkAPI) => {
        try {
            const results = await qmFetchMatchedTrends(params);
            return results;
        } catch (error) {
            console.error("fetchMatchedTrends ThunkAPI error", error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const initialState: MatchedTrendsState = {
    trends: [],
    loading: false,
    error: null,
};

const matchedTrendsSlice = createSlice({
    name: 'matchedTrends',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMatchedTrends.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMatchedTrends.fulfilled, (state, action) => {
                state.loading = false;
                state.trends = action.payload;
            })
            .addCase(fetchMatchedTrends.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const selectMatchedTrends = (state: RootState) => state.matchedTrends.trends;
export const selectMatchedTrendsLoading = (state: RootState) => state.matchedTrends.loading;
export const selectMatchedTrendsError = (state: RootState) => state.matchedTrends.error;

export const matchedTrendsReducer = matchedTrendsSlice.reducer;
