import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { zonedTimeToUtc } from "date-fns-tz";
import {
  qmFetchTradesQuotes,
  qmFetchAnalytics,
} from "@berkindale/berkindale-provider-quotemedia-javascript-api";
import { analyticsIndicatorList, Indicator } from "./analyticsIndicatorList";
import { AppDispatch, RootState } from "../../app/store";
import {
  QMAnalyticsInput,
  QMTradesQuotesInput,
} from "@berkindale/berkindale-provider-quotemedia-javascript-api/dist/quotemedia/quotemediaApis";
import {
  QMAnalytics,
  QMQuote1Minute,
  QMTrade1Minute,
} from "@berkindale/berkindale-provider-quotemedia-domain";
import { getPreviousDay } from "../common/utils";

const previousTradingDay = getPreviousDay(new Date(), true, false).getTime();
export interface TOBState {
  tradesQuotes: { [key: number]: TradeQuote };
  tqLoading: boolean;
  tqError: string | undefined; // string | undefined to match thunkapi rejected error message type
  analytics: { [key: number]: Analytic[] };
  failedAnalytics: Indicator[];
  analyticsLoading: boolean;
  filter: TOBFilter;
}

export interface TOBFilter {
  ticker: string;
  venue: string;
  date: number;
  indicators: Indicator[];
  tqCategory: "trades" | "quotes";
}

export type Analytic = {
  indicator: Indicator;
  data: VolumeData[];
};

type GroupedResponse = {
  [key: string]: { [timestamp: string]: number };
};

// Interfaces that define returned data from api calls
export interface BKTOBTradeQuote {
  timestampAt: number;
  openPrice: number;
  closePrice: number;
  highPrice: number;
  lowPrice: number;
  volume: number;
  time: number;
}

export type PriceData = {
  time: number;
  high: number;
  low: number;
  open: number;
  close: number;
};

export type VolumeData = { value: number; time: number };

export type TradeQuote = {
  priceData: PriceData;
  volumeData: VolumeData;
  status: string;
  empty: boolean;
};

const initialState: TOBState = {
  tradesQuotes: {},
  tqLoading: false,
  tqError: undefined,

  // analytics - {}
  // { "TD20220711": [
  //     {
  //       indicator: {id: "effective_spread", label: ___, group: ___},
  //       data: [ {time: __, value: ___ }, {time: ___, value: ___} ...]
  //     },
  //     {
  //       indicator: {id: "realized_spread", label: ___, group: ___},
  //       data: [ {time: __, value: ___ }, {time: ___, value: ___} ...]
  //     },
  //      ...
  //   ],
  //   "TD20220730": [
  //     {
  //       indicator: {id: ___, label: ___, group: ___},
  //       data: [ {time: __, value: ___ }, {time: ___, value: ___} ...]
  //     }
  //     ...
  //   ]
  // }
  analytics: {},
  failedAnalytics: [],
  analyticsLoading: false,
  filter: {
    ticker: "TD",
    venue: "TSX",
    // date: Number(import.meta.env.VITE_TOB_DEMO_DATE) || 1657857600000, // 1657891800000
    date: previousTradingDay,
    indicators: [],
    tqCategory: "trades",
  },
};

export const getOpenClose = (date: number): [Date, Date] => {
  // TODO: understand that this is a hack
  // assuming user is in eastern time zone and market is toronto
  // opening at 9:30AM and closing at 4:00PM
  const open = new Date(date);
  const close = new Date(date);
  open.setHours(9);
  open.setMinutes(30);
  open.setSeconds(0);
  open.setMilliseconds(0);
  close.setHours(16);
  close.setMinutes(0);
  close.setSeconds(0);
  close.setMilliseconds(0);
  return [open, close];
};

// FETCH_TRADES_QUOTES

export interface FetchTradesQuotesInput {
  tqCategory: "trades" | "quotes";
  ticker: string;
  date: number;
  jwtToken: string;
  hash: number;
}

export type FetchTradesQuotesResponse = [number, TradeQuote];

export const fetchTradesQuotes = createAsyncThunk<
  // Return Type
  FetchTradesQuotesResponse,
  // First agument type
  FetchTradesQuotesInput,
  {
    state: RootState;
  }
>("tobData/fetchTradesQuotes", async (tradesQuotesInput, thunkAPI) => {
  try {
    const state: TOBState = thunkAPI.getState().TOB;

    const { tqCategory, ticker, date, jwtToken, hash } = tradesQuotesInput;
    // if data exist
    if (state.tradesQuotes[hash]) {
      return [hash, state.tradesQuotes[hash]];
    }
    // Date Management
    let dateObj = new Date(date);
    const dateStr = dateObj.toLocaleDateString("fr-CA"); // ref https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript
    dateObj.setHours(0, 0, 0); // Set hours, minutes and seconds
    const from = dateObj.getTime();
    console.log("TOBSlice->fetchTradesQuotes->from", from);

    // api call
    const input: QMTradesQuotesInput = {
      dataset: tqCategory === "quotes" ? "quotes-1minute" : "trades-1minute",
      symbol: ticker + ":CC",
      date: dateStr,
      from,
      limit: 1440,
      jwtToken,
    };
    let resp = await qmFetchTradesQuotes(input);

    console.log("TOBSlice->fetchTradesQuotes-> raw response data", resp);
    resp = resp.sort(
      (
        a: any,
        b: any,
      ) => a.timestamp_at - b.timestamp_at
    );
    console.log("TOBSlice->fetchTradesQuotes-> sorted response data", resp);
    resp = resp.map((element: any) => {
      return {
        timestampAt: element.timestamp_at,
        openPrice: element.open_price,
        closePrice: element.close_price,
        highPrice: element.high_price,
        lowPrice: element.low_price,
        volume: element.volume ?? 0,
      };
    });

    console.log("TOBSlice->fetchTradesQuotes->renamed response data", resp);

    // Lightweight-charts displays the charts in UTC so we subtract the Toronto timezone offset (4/5 hrs) from the timestamp to make it look like Toronto time
    // https://github.com/tradingview/lightweight-charts/blob/7104e9a4fb399f18db7a2868a91b3246014c4324/docs/time-zones.md
    // TODO: ask DL if we should move this timezone adjustment to the chart component and store "real" UTC times in Redux    
    const tzOffsetMin = new Date(date).getTimezoneOffset();
    resp.forEach(
      (el: any) => {
        el.timestampAt.replace(" ", "T")
        el.timestampAt += "Z";
        console.log(new Date(el.timestampAt))
        el.time = new Date(el.timestampAt).getTime() / 1000.0 - tzOffsetMin * 60;
      }
    );
    console.log("TOBSlice->fetchTradesQuotes->Time with data", resp);

    // Filter out results that are outside normal trading hours
    const [open, close] = getOpenClose(date);
    resp = resp.filter(
      (el: BKTOBTradeQuote) =>
        el.time * 1000 >= open.getTime() - tzOffsetMin * 60 * 1000 &&
        el.time * 1000 <= close.getTime() - tzOffsetMin * 60 * 1000
    );

    console.log("TOBSlice->fetchTradesQuotes->Time with data adjusted", resp);

    // Convert each data object into two separate objects (priceData, volumeData) with properties that are acceptable to lightweight-charts
    // We kept the previous resp.map() call where properties such as to timestampAt, openPrice, etc. were set, because we may want that formatting (single object with ohlcv) if this data is used elsewhere in the app.
    const priceData: any = (resp || []).map((el: BKTOBTradeQuote) => {
      return {
        time: el.time,
        open: el.openPrice,
        high: el.highPrice,
        low: el.lowPrice,
        close: el.closePrice,
      };
    });
    const volumeData: VolumeData = (resp || []).map((el: BKTOBTradeQuote) => ({
      value: el.volume,
      time: el.time,
    }));
    console.log("priceData", priceData);
    console.log("volumeData", volumeData);
    return [
      hash,
      {
        priceData,
        volumeData,
        status: "succeeded",
        empty: Object.keys(priceData).length === 0,
      },
    ];
  } catch (error) {
    console.log("TradesQuotes ThunkAPI error", error);
    return thunkAPI.rejectWithValue(error);
  }
});

const filterTradingHours = (date: number, response: QMAnalytics[]) => {
  const [open, close] = getOpenClose(date);
  return response.filter(
    (el: any) =>
      el.timestamp >= open.getTime() && el.timestamp <= close.getTime()
  );
};

/**
 * Helper function for fetchAnalytics
 * Group all analytics values of a certain type into its own object
 * Do this grouping for each type of analytics in the response
 * @param {object[]} response
 * @returns object
 */
const groupAnalyticsValues = (response: QMAnalytics[]) => {
  const groupedResponse: GroupedResponse = {};
  // group each set of analytic values into its own object
  response.forEach((el: any) => {
    for (const key in el) {
      if (key !== "timestamp") {
        if (groupedResponse[key] === undefined) {
          groupedResponse[key] = {};
        }
        groupedResponse[key][el.timestamp] = el[key];
      }
    }
  });
  return groupedResponse;
};
/**
 * Helper function for fetchAnalytics
 * Create an array with all of the returned analytics for a category
 * Each set of analytic data is in an array inside an object, along with indicator info
 * @param {object} groupedResponse
 * @returns object[]
 */
const arrangeAnalyticsCategoryData = (groupedResponse: GroupedResponse) => {
  const analyticsForSingleCategory = [];
  const tzOffsetMin = new Date().getTimezoneOffset(); /// hardcode tz offset hours as downstream charts display in UTC
  for (const [indicatorId, timeAndValue] of Object.entries(groupedResponse) as [
    indicatorId: string,
    timeAndValue: any
  ]) {
    const tmpIndicator = analyticsIndicatorList.find((ind) => {
      return ind.id === indicatorId;
    });
    // prepare an object to add to analyticsForSingleCategory
    if (tmpIndicator) {
      const analytic: Analytic = {
        indicator: tmpIndicator,
        data: [],
      };
      // loop through each key:value pair in timeAndValue and change timestamps to local and change value based on indicator.scale
      for (const [time, value] of Object.entries(timeAndValue) as [
        string,
        number
      ][]) {
        // these analytics return a timestamp with milliseconds (13-digit epoch timestamp)
        let tmpData: { time: number; value: number } = {
          time: Number(time) / 1000.0 - tzOffsetMin * 60,
          value: value * (analytic.indicator.scale || 1.0),
        };

        // I added "+ 3600" to the end of the time calculation because the data before 2022-11-07 was an hour behind
        if (tmpData.time > 1667779140) {
          tmpData = {
            ...tmpData,
            time: tmpData.time - 3600,
          };
        }
        analytic.data.push(tmpData);
      }
      analyticsForSingleCategory.push(analytic);
    }
  }
  return analyticsForSingleCategory;
};

const addDummyResponse = (timestamp: number, groupIndicators: String[], response: QMAnalytics[]) => {
  // @ts-expect-error
  response.push({
    timestamp: timestamp,
  });

  for (let i = 0; i < groupIndicators.length; i++) {
    // @ts-expect-error
    response[response.length - 1][groupIndicators[i]] = 0;
  }

  console.log(response[-1]);
}

// FETCH_ANALYTICS
export interface FetchAnalyticsInput {
  ticker: string;
  venue: string;
  date: number;
  indicators: Indicator[];
  jwtToken: string;
  hash: number;
}

export type FetchAnalyticsResponse = [number, Analytic[], Indicator[]];

export const fetchAnalytics = createAsyncThunk<
  //Return type
  FetchAnalyticsResponse,
  // Input type
  FetchAnalyticsInput,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>("tobData/fetchAnalytics", async (analyticInput, thunkAPI) => {
  const { ticker, venue, date, indicators, jwtToken, hash } = analyticInput;
  const symbol = ticker + ":CC";
  // const venue = "TSX";

  // Analytics that are successfully fetched from the API
  let newAnalytics: Analytic[] = [];
  // A list of the indicators/analytics that suffered failed API calls during this round of API calls.
  let failedAnalytics: Indicator[] = [];
  // A list of the analyticsCategories that have been successfully called during this round of API calls.
  let successfulAnalyticsCategories: string[] = [];
  // Check if the existing analytics in the redux store include a record with the given hash
  // i.e., the existing analytics for a particular ticker symbol on a particular date
  let existingAnalytics: Analytic[] =
    thunkAPI.getState().TOB.analytics[hash] || [];

  let indicatorsPerGroup: { [key: string]: string[] } = {};
  for (const indicator of indicators) {
    if (indicator.group in indicatorsPerGroup) {
      indicatorsPerGroup[indicator.group].push(indicator.id);
    } else {
      indicatorsPerGroup[indicator.group] = [indicator.id];
    }
  }

  let responses: [string, string[], Promise<QMAnalytics[]>][] = [];

  for (const group in indicatorsPerGroup) {
    let groupIndicators = indicatorsPerGroup[group];
    for (var i = groupIndicators.length - 1; i >= 0; i--) {
      const indicatorId = groupIndicators[i];

      // If existingAnalytics includes an analytic with this indicator.id, then no need to call the API
      let tmpAnalytic = existingAnalytics.find(
        (analytic: Analytic) => indicatorId === analytic.indicator.id
      );
      if (tmpAnalytic) {
        let indexToRemove = groupIndicators.indexOf(indicatorId);
        groupIndicators.splice(indexToRemove, 1);
      }
    }

    // If the analytic is not in existingAnalytics, then start preparing the API call (gather the params to be sent to the API)
    if (groupIndicators.length > 0) {
      thunkAPI.dispatch(setIsAnalyticsLoading());
      let extraArgs = null;
      if (group === "trades_trading_cost") {
        extraArgs = { resample_ms: 60 * 1000, indicators: groupIndicators.join(',') };
      } else {
        extraArgs = { indicators: groupIndicators.join(',') };
      }

      let dateObj = new Date(date);
      let dateStr = dateObj.toLocaleDateString("fr-CA"); // ref https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript

      // TODO: replace hardcoded venue open and close times and hardcoded timeZone
      /**
       * Setting an earlier venueOpenTime (9:29 a.m. ET) ensures that a 9:30 a.m. ET record is fetched for certain analytics categories.
       * Otherwise the response data is incomplete - the first record would be for 9:31 a.m. ET.
       * This is a temporary fix until the SQL queries in the Analytics Python lambda code are fixed.
       */
      const venueOpenTime =
        group === "trades_trading_cost"
          ? dateStr + " 09:30:00"
          : dateStr + " 09:29:00";
      const venueCloseTime = dateStr + " 16:00:00";
      const timeZone = "America/Toronto";
      const startTimestamp = zonedTimeToUtc(venueOpenTime, timeZone).getTime();
      const endTimestamp = zonedTimeToUtc(venueCloseTime, timeZone).getTime();
      console.log(
        "startTimestamp",
        startTimestamp,
        "endTimestamp",
        endTimestamp
      );
      let input: QMAnalyticsInput = {
        name: group,
        symbol,
        venue,
        date: dateStr,
        startTimestamp,
        endTimestamp,
        jwtToken,
        extraArgs,
      };
      responses.push([group, groupIndicators, qmFetchAnalytics(input)]);
    }
  }

  try {
    await Promise.allSettled(responses.map(response => response[2])).then(analyticsResponses => {
      for (var i = 0; i < analyticsResponses.length; i++) {
        let raw_response = analyticsResponses[i];
        console.log("TOB fetchAnalytics - raw response", raw_response);

        const group: string = responses[i][0];
        const groupIndicators: string[] = responses[i][1];
        if (raw_response.status === "fulfilled") {
          let response: QMAnalytics[] = raw_response.value;

          if (raw_response.value.length == 0) {
            for (const indicatorId of groupIndicators) {
              const failedIndicator = indicators.find((indicator: Indicator) => indicatorId === indicator.id);
              if (failedIndicator) {
                failedAnalytics.push(failedIndicator);
              }
            }
          }
          else {

            /**
           * Add a placeholder record with a timestamp of 4:00 p.m. ET to the trades_trading_cost data.
           * The last record in the response data is 3:59 p.m. ET.
           * This is a temporary fix until the SQL queries in the Analytics Python lambda code are fixed.
           */
            if (group === "trades_trading_cost") {
              const secondLastTimestamp = response[response.length - 1].timestamp;
              console.log("secondLast", secondLastTimestamp);
              addDummyResponse(secondLastTimestamp + 60000, groupIndicators, response);
            }
            /**
             * Add a placeholder record with a timestamp of 9:30 a.m. ET to the trades_trading_imbalance data.
             * The first record in the response data is 9:31 a.m. ET.
             * This is a temporary fix until the SQL queries in the Analytics Python lambda code are fixed.
             */
            if (group === "trades_trading_imbalance") {
              const secondTimestamp = response[0].timestamp;
              addDummyResponse(secondTimestamp - 60000, groupIndicators, response);
              response.sort((a, b) => a.timestamp - b.timestamp);
            }
            /**
             * Add a placeholder record with a timestamp of 9:30 a.m. ET to the trades_fragmentation data.
             * The first record in the response data is 9:31 a.m. ET.
             * This is a temporary fix until the SQL queries in the Analytics Python lambda code are fixed.
             */
            if (group === "trades_fragmentation") {
              const secondTimestamp = response[0].timestamp;
              addDummyResponse(secondTimestamp - 60000, groupIndicators, response);
              response.sort((a, b) => a.timestamp - b.timestamp);
            }
            if (
              group === "tob_quote_activity_consolidated" ||
              group === "tob_quote_activity_venue"
            ) {
              response = filterTradingHours(date, response);
            }
            const groupedResponse = groupAnalyticsValues(response);

            const analyticsForSingleCategory =
              arrangeAnalyticsCategoryData(groupedResponse);
            console.log(
              "TOB fetchAnalytics - analyticsForSingleCategory",
              analyticsForSingleCategory
            );
            if (!successfulAnalyticsCategories.includes(group)) {
              newAnalytics = [...newAnalytics, ...analyticsForSingleCategory];
              successfulAnalyticsCategories.push(group);
            }
          }
        }
        else {
          const error = raw_response.reason;

          // Ensure that error message for expired JWT reaches the TOBPage so it can be refreshed
          if (error.response.status === 401 || error.response.status === 403) {
            return Promise.reject(error);
            // If the error is not an unauthorized 401 error, then add the indicator to the list of failed indicators.
          } else {
            for (const indicatorId of groupIndicators) {
              const failedIndicator = indicators.find((indicator: Indicator) => indicatorId === indicator.id);
              if (failedIndicator) {
                failedAnalytics.push(failedIndicator);
              }
            }
          }
        }
      }
    });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }


  return [hash, newAnalytics, failedAnalytics];
  // } catch (error) {
  // console.log("fetchAnalytics ThunkAPI error", error);
  //
  // }
});

export const TOBSlice = createSlice({
  name: "TOB",
  initialState,
  reducers: {
    setIsAnalyticsLoading: (state) => {
      state.analyticsLoading = true;
    },
    updateTOBFilter: (state, action) => {
      state.filter.ticker = action.payload.ticker;
      state.filter.venue = action.payload.venue;
      state.filter.date = action.payload.date;
      state.filter.indicators = action.payload.indicators;
      state.filter.tqCategory = action.payload.tqCategory;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradesQuotes.pending, (state) => {
        state.tqLoading = true;
        state.tqError = undefined;
      })
      .addCase(fetchTradesQuotes.fulfilled, (state, action) => {
        const [hash, data] = action.payload;
        state.tradesQuotes[hash] = data;
        console.log("set state.tradesQuotes: ", data);
        state.tqLoading = false;
      })
      .addCase(fetchTradesQuotes.rejected, (state, action) => {
        console.log("ERROR fetchTradesQuotes.rejected"); // payload object is not available on a rejected promise if it was not handled with rejectWithValue.
        state.tqLoading = false;
        state.tqError = action.error.message;
      })
      .addCase(fetchAnalytics.pending, (state, action) => {
        // We don't set state.analyticsLoading here.
        // Instead, we set it in the fetchAnalytics thunk, but only in instances when we actually have to call the API.
        // If we don't have to call the API (i.e., because we already have the requested analytic in existingAnalytics), then we don't want to set analytics loading as true (we don't want the user to see a spinner).
        state.failedAnalytics = [];
      })
      .addCase(fetchAnalytics.fulfilled, (state, action) => {
        const [hash, newAnalytics, failedAnalytics] = action.payload;

        // Add newAnalytics to redux store
        if (state.analytics[hash]) {
          state.analytics[hash] = [...state.analytics[hash], ...newAnalytics];
        } else {
          state.analytics[hash] = [...newAnalytics];
        }

        // Add failedAnalytics to redux Store
        let ids = failedAnalytics.map((indicator) => indicator.id);
        state.filter.indicators = state.filter.indicators.filter(
          (indicator) => !ids.includes(indicator.id)
        );
        state.failedAnalytics = failedAnalytics;

        state.analyticsLoading = false;
      })
      .addCase(fetchAnalytics.rejected, (state, action) => {
        state.analyticsLoading = false;
      });
  },
});

// ACTION CREATORS
export const { setIsAnalyticsLoading, updateTOBFilter } = TOBSlice.actions;

// SELECTORS
export const selectTradesQuotes = (state: RootState) =>
  (state.TOB || { tradesQuotes: {} }).tradesQuotes;
export const selectTQLoading = (state: RootState) => state.TOB.tqLoading;
export const selectTQError = (state: RootState) => state.TOB.tqError;
export const selectAnalytics = (state: RootState) =>
  (state.TOB || { analytics: {} }).analytics;
export const selectFailedAnalytics = (state: RootState) =>
  state.TOB.failedAnalytics;
export const selectAnalyticsLoading = (state: RootState) =>
  state.TOB.analyticsLoading;
export const selectTOBFilter = (state: RootState) => state.TOB.filter;

export const TOBReducer = TOBSlice.reducer;
